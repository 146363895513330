body {
    padding: 0%;
    margin: 0%;
    width: 100%;
    height: 100vh;
}

html {
    padding: 0%;
    margin: 0%;
    width: 100%;
}

.root-div {
    padding: 0%;
    margin: 0%;
    width: 100%;
    height: 100vh;
}